
import React, { useEffect, useState } from 'react';
import * as styles from './Banner.module.scss';
import logoImg from '../../assets/IMG/Logo-New-Full.png';
import firstImg from '../../assets/IMG/Banner/1.webp';
import secondImg from '../../assets/IMG/Banner/2.webp';
import thirdImg from '../../assets/IMG/Banner/3.webp';
import forthImg from '../../assets/IMG/Banner/4.webp';
import fifthImg from '../../assets/IMG/Banner/5.webp';

const Banner: React.FC = () => {
  const images: string[] = [firstImg, secondImg, thirdImg, forthImg, fifthImg];
  const [image, setImage] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      if ((image + 1) === images.length)
        setImage(0)
      else
        setImage(image + 1)
    }, 7500);
  }, [image]);

  return (<div className={`${styles.Banner}`}>
    {
      images.map((background: string, index: number) => {
        return <div className={`${styles.BannerImage} ${index === image ? styles.Show : ""}`} style={{ backgroundImage: `url('${background}')` }}></div>
      })
    }
    <div className={styles.BannerInner}>
      <div className={styles.BannerContent}>
        <div className={styles.BannerLogo}>
          <img loading={"lazy"} src={logoImg} />
        </div>
      </div>
    </div>
  </div>);
};

export default Banner;
