import React from 'react';
import Cookies from '../components/Cookies/Cookies';
import Layout from '../components/Layout/layout';

const PrivacyPage: React.FC = () => {
  return (
    <Layout>
      <Cookies />
    </Layout>
  );
};

export default PrivacyPage;
